import React from 'react';

import NextAbout from './NextAbout';
import NextMembership from './NextMembership';
import NextFounders from './NextFounders';
import NextAdvisors from './NextAdvisors';

export default function ErtNext() {
  return (
    <>
      <NextAbout />
      <NextMembership />
      <NextFounders />
      <NextAdvisors />
    </>
  );
}