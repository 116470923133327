import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import SectionHeader from '../../../shared/section-header/SectionHeader';

import './NextAbout.scss';

export default function NextAbout() {
  return (
    <div className="NextAbout page-section-auto blackish-background">
      <div className="catch-link" id="what-is-ert-next" />

      <div className="container">
        <div className="h-gap"></div>
        <SectionHeader next>
          WHAT IS ERT <span className="next">NEXT</span>?
        </SectionHeader>

        <div className="body">
          <div className="left">
            <ScrollAnimation animateIn="zoomIn" animateOnce delay={300}>
              <p>
                <span className="strong">Founded in 2025</span> by <a href="#next_thomas_ladner">Thomas Ladner</a> (Chairman), <a href="#next_pascal_forster">Pascal Forster</a> and <a href="#next_pascal_forster">Thomas Hochstrasser</a>, <span className="strong">ERT <span className="next">NEXT</span></span> is Switzerland's most relevant community of next generation leaders and entrepreneurs. Its hand-picked members enjoy a privileged interaction with the <span className="strong">ERT <span className="next">NEXT</span></span> <a href="#the-advisory-board-of-ert-next">Advisory Board</a> comprising of prominent Swiss business leaders.
              </p>
              <p>
                <span className="strong">ERT <span className="next">NEXT</span></span> members meet once a quarter for a relaxed dinner and personal exchanges at the invitation of an <a href="#the-advisory-board-of-ert-next">Advisory Board</a> member. An <span className="strong">ERT <span className="next">NEXT</span></span> luncheon is also held every other month at the <span className="strong">Club zum Rennweg</span>.
              </p>
            </ScrollAnimation>
          </div>

          <div className="space"></div>
          <div className="right">
            <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
              <div>
                <span className="quote">“</span>
                <p>
                  <span className="strong">ERT <span className="next">NEXT</span></span> grew out of the Entrepreneurs' Roundtable, Switzerland's most exclusive community of business leaders. It provides a secure platform for the next generation of leaders to establish trusted friendships and to interact with CEOs and Chairmen of the country's largest companies.
                </p>
              </div>

            </ScrollAnimation>
          </div>
        </div>

      </div>

    </div>
  );
}