import React from "react";

import SectionHeader from "../../../shared/section-header/SectionHeader";
import AdvisorPeopleCard from "../../../shared/advisor-people-card/AdvisorPeopleCard";

import "./NextAdvisors.scss";

import NextAdvisorsData from "./next-advisors-data.json";

export default function NextAdvisors() {
  return (
    <div className="NextAdvisors">
      <div className="people-pages">
        {NextAdvisorsData.map((country) => (
          <div className="page-section-auto container" key={country.id}>
            <div className="catch-link" id={country.id} />
            <SectionHeader next>
              ERT <span className="next" >NEXT</span> ADVISORY BOARD
            </SectionHeader>

            <div className="body">
              <div className="people">
                {country.people.map((person, index) => (
                  <AdvisorPeopleCard
                    key={person.id}
                    index={index}
                    name={person.name}
                    image={"assets/images/advisors/" + person.image + ".png"}
                    descriptions={person.descriptions}
                    next
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
