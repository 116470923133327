import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

import SectionHeader from '../../../shared/section-header/SectionHeader';

import './Contact.scss';


export default function Contact() {
  return (
    <div className="Contact blackish-background" id="contact">
      <div className="container">
        <SectionHeader>
          CONTACT
        </SectionHeader>

        <ScrollAnimation animateIn="fadeIn" animateOnce delay={300}>
          <div className="body">
            <div className="title">
              ERT <span className="next">NEXT</span> &nbsp; | &nbsp; Entrepreneurs' Roundtable AG
            </div>
            <div className="address">
              Grabenstrasse 25 &nbsp; • &nbsp; 6340 Baar &nbsp; • &nbsp; Switzerland
            </div>
            <div className="address">
             <a href="mailto:contact@e-r-t.org" target="_blank" rel="noopener noreferrer">contact@e-r-t.org</a>
            </div>
            <div className="bottom-link">
              <span className="copyright">{new Date().getFullYear()} ©by Entrepreneurs' Roundtable AG. All rights reserved.</span>
              <span><Link to="/privacy_policy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link></span>
            </div>
          </div>
        </ScrollAnimation>

      </div>
    </div>
  );
}
