import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import SectionHeader from '../../../shared/section-header/SectionHeader';

import './NextMembership.scss';

export default function NextMembership() {
  return (
    <div className="NextMembership page-section-auto container">
      <div className="catch-link" id="becoming-a-member-of-ert-next" />
      <SectionHeader next>
        ERT <span className="next" >NEXT</span> MEMBERSHIP
      </SectionHeader>

      <div className="body">

        <div className="left">
          <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={300}>
            {/* <img src="/assets/images/membership/member-text-1.png" alt="member-text-1" className="member-text-img-1" /> */}

            <p className="line1">«MEMBERSHIP CRITERIA»</p>
            <p className="line5">Invitation to become a member is</p>
            <p className="line6">based on an exceptional</p>
            <p className="line7">track record as an</p>
            <p className="line8">entrepreneur or a business</p>
            <p className="line9">Leader.</p>

            <p className="line2">Membership is subject to</p>
            <p className="line3">unanimous approval by the</p>
            <p className="line4">Advisory Board.</p>

            {/* Invitation to become a member is based on an exceptional track record as an entrepreneur or a business leader. */}
          </ScrollAnimation>
        </div>

        {/* <div className="space" /> */}

        <div className="right">
          <ScrollAnimation animateIn="fadeInDown" animateOnce delay={300}>
            <div className="m-container">
              <div className="v-line"></div>
              <div className="membership-fees">
                <p className="line1">Fees upon acceptance:</p>
                <p className="line2">One-time admission fee</p>
                <p className="line3">&</p>
                <p className="line4">annual age-based membership fee</p>
              </div>
            </div>
            <div className="m-contact">
              Applications may be sent to <a
                          href="mailto:contact@ertnext.org"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="email next"
                        >
                          contact@ertnext.org
                        </a>
            </div>
            {/* <div className="yearly-fees">
              <div className="fee none"></div>
              <div className="fee">
                <div>
                  <p className="line1"> Below 35 yrs</p>
                  <p className="line2">CHF 3000</p>
                </div>
              </div>
              <div className="fee">
                <div>
                  <p className="line1">35-39 yrs</p>
                  <p className="line2">CHF 4000</p>
                </div>
              </div>
              <div className="fee">
                <div>
                  <p className="line1">40-45 yrs</p>
                  <p className="line2">CHF 5000</p>
                </div>
              </div>
            </div> */}
          </ScrollAnimation>
        </div>

      </div>
    </div>
  );
}