import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import './SectionHeader.scss';

export default function SectionHeader(props) {
  return (
    <ScrollAnimation animateIn="fadeIn" animateOnce delay={50}>
      <div className="SectionHeader">
        <div className="title">
          {props.children}
        </div>
        <div className={`bar${props.next ? " next" : ""}`} />
      </div>
    </ScrollAnimation>
  );
}