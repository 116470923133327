import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

import SectionHeader from "../../../shared/section-header/SectionHeader";
import AboutPeopleCard from "../../../shared/about-people-card/AboutPeopleCard";

import "./Founders.scss";

export default function Founders() {
  return (
    <div className="Founders">
      <div className="catch-link" id="founders" />

      <div className="founder-section page-section-auto container">
        <SectionHeader>THE FOUNDERS</SectionHeader>

        <div className="common-text">
          <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
            Pascal Forster and Thomas Ladner met during their studies at the
            University of St. Gallen HSG in Switzerland. Throughout their
            professional lives they have been passionate about creating
            communities of business and thought leaders. In 1999, Thomas founded
            the Entrepreneurs' Roundtable which Pascal joined as co-founder in
            2001. They continued to co-found several other organizations such as
            the prestigious Club zum Rennweg (
            <a
              href="https://www.clubzumrennweg.ch"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.clubzumrennweg.ch
            </a>
            ), the Zurich-based Grasshopper Griffith-Club and WORLD.MINDS
            Foundation (
            <a
              href="https://www.worldminds.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.worldminds.com
            </a>
            ).
          </ScrollAnimation>
        </div>

        <div className="body">
          <div className="AboutPeopleCard">
            <div className="catch-link-founder" id="thomas_ladner" />
            <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
              <div className="img-area">
                <div className="img-container">
                  <img
                    src="/assets/images/founders/Dr. Thomas Ladner.png"
                    alt="Dr. Thomas Ladner"
                  />
                </div>

                <div className="about">
                  <div className="name">Dr. Thomas Ladner</div>

                  <div className="designations">
                    <p>Attorney at law</p>
                  </div>

                  <div className="email-container">
                    <a
                      href="mailto:thomas.ladner@e-r-t.org"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="email"
                    >
                      thomas.ladner@e-r-t.org
                    </a>
                  </div>

                  <a
                    href="https://linkedin.com/in/dr-thomas-ladner-704522"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="linkedin-icon-link"
                  >
                    <img
                      src="/assets/icons/linkedIn-icon.png"
                      alt="LinkedIn"
                      className="linkedin-icon"
                    />
                  </a>
                </div>
              </div>
            </ScrollAnimation>
            <div className="data">
              <ScrollAnimation animateIn="zoomIn" animateOnce delay={300}>
                <div className="testimonial">
                  <p>
                    Thomas Ladner started his legal career with the law firm
                    Homburger in Zurich. From 2000 - 2004, he lectured on
                    corporate law and contract law at the University of St.
                    Gallen HSG from where he also holds a Ph.D. In 2002, he
                    joined one of Switzerland’s leading law firms
                    Meyerlustenberger Lachenal as their youngest Partner.
                    In 2009, Thomas moved on to become Partner at a San
                    Francisco-based Private Equity firm in charge of their
                    European investments (until 2014) while simultaneously
                    serving as Counsel with MLL Legal AG, a position he still holds.
                  </p>
                  <p>
                    In a personal capacity, Thomas founded and co-founded a
                    number of tech and financial services businesses, two of
                    which went public. He was the Founding Chairman of the Club
                    zum Rennweg and of WORLD.MINDS Foundation and the Founding
                    Vice-Chairman of the Grasshopper Griffith Club. He also
                    served on the board of the Swiss Management Association
                    (Schweizerische Management Gesellschaft SMG) for seven
                    years.
                  </p>
                  <p>
                    Today Thomas acts as professional board member in more than
                    a dozen companies ranging from biotech and blockchain
                    start-ups to mature companies in the construction,
                    investment, trading and service industries. In
                    addition he is a member of the board of the Swiss Defence Society.
                    The leading Swiss business publication Bilanz describes him as one of
                    Switzerland’s{" "}
                    <span className="italic">
                      “most influential business lawyers“
                    </span>
                    .
                  </p>
                </div>
              </ScrollAnimation>
            </div>
          </div>

          <div className="AboutPeopleCard">
            <div className="catch-link-founder" id="pascal_forster" />
            <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
              <div className="img-area">
                <div className="img-container">
                  <img
                    src="/assets/images/founders/Pascal Forster.png"
                    alt="Pascal Forster"
                  />
                </div>

                <div className="about">
                  <div className="name">Pascal Forster</div>
                  <div className="designations">
                    <p>Executive Search Professional</p>
                  </div>
                  <div className="email-container">
                    <a
                      href="mailto:pascal.forster@e-r-t.org"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="email"
                    >
                      pascal.forster@e-r-t.org
                    </a>
                  </div>

                  <a
                    href="https://ch.linkedin.com/in/pascal-forster-23116424"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="linkedin-icon-link"
                  >
                    <img
                      src="/assets/icons/linkedIn-icon.png"
                      alt="LinkedIn"
                      className="linkedin-icon"
                    />
                  </a>
                </div>
              </div>
            </ScrollAnimation>
            <div className="data">
              <div className="testimonial">
                <ScrollAnimation animateIn="zoomIn" animateOnce delay={300}>
                  <p>
                    Pascal Forster (born 1967) is an expert in executive search,
                    executive assessment, and leadership coaching. He is the
                    founder of Forster & People, a firm dedicated to advising
                    CEOs, chairpersons, and boards on leadership and talent
                    decisions. Before establishing his own company, he was a
                    Managing Director at Russell Reynolds Associates, where he
                    worked with global organizations on leadership succession
                    and board effectiveness. Earlier in his career, he honed his
                    expertise at Dr. Bjørn Johansson Associates, a boutique
                    executive search firm in Zurich, where he learned to operate
                    at the highest levels of corporate leadership.
                  </p>
                  <p>
                    Beyond his core business, Pascal is an active investor in
                    early-stage companies and a co-founder of several
                    influential global communities, including the Entrepreneurs
                    Roundtable, Club zum Rennweg, and WORLD.MINDS, fostering
                    exchanges between leading business figures, thinkers, and
                    innovators. In 1999, he and his wife Margarita established
                    Fundación Avanzar, a foundation supporting women in Ecuador
                    to become entrepreneurs, through education, mentoring, and
                    financing programs.
                  </p>
                  <p>
                    He holds an MBA from the University of St. Gall and
                    completed the Executive Masters program in Consulting and
                    Coaching for Change from INSEAD.
                  </p>
                  <p>
                    In his personal life, Pascal is passionate about athletics
                    and endurance sports, regularly competing in Hyrox events,
                    swimming, and cross-country skiing.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="co-founder-section page-section-auto container">
        <div className="body">
          <div className="side left">
            <SectionHeader>
              CO-FOUNDER <br /> FRANCE CHAPTER
            </SectionHeader>

            <AboutPeopleCard
              imagePath="assets/images/founders/Carl Azar.png"
              name="Carl Azar"
              designations={[]}
            />
          </div>
        </div>
        <div className="body">
          <div className="side centre">
            <SectionHeader>
              CO-FOUNDER <br />
              NETHERLANDS CHAPTER
            </SectionHeader>

            <AboutPeopleCard
              imagePath="assets/images/testimonials/Ton Buchner.png"
              name="Ton Büchner"
              designations={[]}
            />
          </div>
        </div>
        <div className="body">
          <div className="side centre">
            <SectionHeader>
              CO-FOUNDER <br />
              POLAND CHAPTER
            </SectionHeader>

            <AboutPeopleCard
              imagePath="assets/images/founders/Wojciech Szczepaniak.png"
              name="Wojciech Szczepaniak"
              designations={[]}
            />
          </div>
          <div className="side right">
            <SectionHeader>
              CO-FOUNDER <br />
              POLAND CHAPTER
            </SectionHeader>

            <AboutPeopleCard
              imagePath="assets/images/founders/markus_sieger.png"
              name="Markus Sieger"
              designations={[]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
