import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import SectionHeader from "../../../shared/section-header/SectionHeader";

import "./NextFounders.scss";

export default function NextFounders() {
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);

  return (
    <div className="NextFounders">
      <div className="catch-link" id="the-founders-of-ert-next" />

      <div className="founder-section page-section-auto container">
        <SectionHeader next>
          ERT <span className="next" >NEXT</span> FOUNDERS
        </SectionHeader>

        <div className="common-text">
          <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
            Pascal Forster and Thomas Ladner met during their studies at the
            University of St. Gallen HSG in Switzerland. Throughout their
            professional lives they have been passionate about creating
            communities of business and thought leaders. In 1999, Thomas founded
            the Entrepreneurs' Roundtable which Pascal joined as co-founder in
            2001. They continued to co-found several other organizations such as
            ERT <span className="next">NEXT</span> (together with Thomas Hochstrasser), the prestigious Club zum Rennweg (
            <a
              href="https://www.clubzumrennweg.ch"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.clubzumrennweg.ch
            </a>
            ), the Zurich-based Grasshopper Griffith-Club and WORLD.MINDS
            Foundation (
            <a
              href="https://www.worldminds.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.worldminds.com
            </a>
            ).
          </ScrollAnimation>
        </div>

        <div className="body">
          <div className="AboutPeopleCard">
            <div className="catch-link-founder" id="next_thomas_ladner" />
            <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
              <div className="img-area">
                <div className="img-container">
                  <img
                    src="/assets/images/founders/Dr. Thomas Ladner.png"
                    alt="Dr. Thomas Ladner"
                  />
                </div>

                <div className="about">
                  <div className="angle"></div>
                  <button onClick={() => setIsVisible1(!isVisible1)}>more</button>
                  <div className="right">
                    <div className="name">Dr. Thomas Ladner</div>
                    <div className="designations">
                      <p>Attorney at law</p>
                    </div>
                    <div className="email-container">
                      <div className="email-address" >
                        <a
                          href="mailto:thomas.ladner@e-r-t.org"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="email"
                        >
                          thomas.ladner@e-r-t.org
                        </a>
                      </div>
                      <div className="email-address" >
                        <a
                          href="mailto:thomas.ladner@ertnext.org"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="email next"
                        >
                          thomas.ladner@ertnext.org
                        </a>
                      </div>
                    </div>
                    <a
                      href="https://linkedin.com/in/dr-thomas-ladner-704522"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linkedin-icon-link"
                    >
                      <img
                        src="/assets/icons/linkedIn-icon.png"
                        alt="LinkedIn"
                        className="linkedin-icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
            <div className={isVisible1 ? "data data-show" : "data"}>
              <button className="button-hide" onClick={() => setIsVisible1(!isVisible1)}>hide</button>
              <div className="testimonial">
                <ScrollAnimation animateIn="zoomIn" animateOnce delay={300}>
                  <p>
                    Thomas Ladner started his legal career with the law firm
                    Homburger in Zurich. From 2000 - 2004, he lectured on
                    corporate law and contract law at the University of St.
                    Gallen HSG from where he also holds a Ph.D. In 2002, he
                    joined one of Switzerland’s leading law firms
                    Meyerlustenberger Lachenal as their youngest Partner.
                    In 2009, Thomas moved on to become Partner at a San
                    Francisco-based Private Equity firm in charge of their
                    European investments (until 2014) while simultaneously
                    serving as Counsel with MLL Legal AG, a position he still holds.
                  </p>
                  <p>
                    In a personal capacity, Thomas founded and co-founded a
                    number of tech and financial services businesses, two of
                    which went public. He was the Founding Chairman of the Club
                    zum Rennweg and of WORLD.MINDS Foundation and the Founding
                    Vice-Chairman of the Grasshopper Griffith Club. He also
                    served on the board of the Swiss Management Association
                    (Schweizerische Management Gesellschaft SMG) for seven
                    years.
                  </p>
                  <p>
                    Today Thomas acts as professional board member in more than
                    a dozen companies ranging from biotech and blockchain
                    start-ups to mature companies in the construction,
                    investment, trading and service industries. In
                    addition he is a member of the board of the Swiss Defence Society.
                    The leading Swiss business publication Bilanz describes him as one of
                    Switzerland’s{" "}
                    <span className="italic">
                      “most influential business lawyers“
                    </span>
                    .
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>

          <div className="AboutPeopleCard">
            <div className="catch-link-founder" id="next_pascal_forster" />
            <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
              <div className="img-area">
                <div className="img-container">
                  <img
                    src="/assets/images/founders/Pascal Forster.png"
                    alt="Pascal Forster"
                  />
                </div>

                <div className="about">
                  <div className="angle"></div>
                  <button onClick={() => setIsVisible2(!isVisible2)}>more</button>
                  <div className="right">
                    <div className="name">Pascal Forster</div>
                    <div className="designations">
                      <p>Executive Search Professional</p>
                    </div>
                    <div className="email-container">
                      <div className="email-address" >
                        <a
                          href="mailto:pascal.forster@e-r-t.org"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="email"
                        >
                          pascal.forster@e-r-t.org
                        </a>
                      </div>
                      <div className="email-address" >
                        <a
                          href="mailto:pascal.forster@ertnext.org"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="email next"
                        >
                          pascal.forster@ertnext.org
                        </a>
                      </div>
                    </div>
                    <a
                      href="https://ch.linkedin.com/in/pascal-forster-23116424"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linkedin-icon-link"
                    >
                      <img
                        src="/assets/icons/linkedIn-icon.png"
                        alt="LinkedIn"
                        className="linkedin-icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
            <div className={isVisible2 ? "data data-show" : "data"}>
              <button className="button-hide" onClick={() => setIsVisible2(!isVisible2)}>hide</button>
              <div className="testimonial">
                <ScrollAnimation animateIn="zoomIn" animateOnce delay={300}>
                  <p>
                    Pascal Forster (born 1967) is an expert in executive search,
                    executive assessment, and leadership coaching. He is the
                    founder of Forster & People, a firm dedicated to advising
                    CEOs, chairpersons, and boards on leadership and talent
                    decisions. Before establishing his own company, he was a
                    Managing Director at Russell Reynolds Associates, where he
                    worked with global organizations on leadership succession
                    and board effectiveness. Earlier in his career, he honed his
                    expertise at Dr. Bjørn Johansson Associates, a boutique
                    executive search firm in Zurich, where he learned to operate
                    at the highest levels of corporate leadership.
                  </p>
                  <p>
                    Beyond his core business, Pascal is an active investor in
                    early-stage companies and a co-founder of several
                    influential global communities, including the Entrepreneurs
                    Roundtable, Club zum Rennweg, and WORLD.MINDS, fostering
                    exchanges between leading business figures, thinkers, and
                    innovators. In 1999, he and his wife Margarita established
                    Fundación Avanzar, a foundation supporting women in Ecuador
                    to become entrepreneurs, through education, mentoring, and
                    financing programs.
                  </p>
                  <p>
                    He holds an MBA from the University of St. Gall and
                    completed the Executive Masters program in Consulting and
                    Coaching for Change from INSEAD.
                  </p>
                  <p>
                    In his personal life, Pascal is passionate about athletics
                    and endurance sports, regularly competing in Hyrox events,
                    swimming, and cross-country skiing.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>


          <div className="AboutPeopleCard">
            <div className="catch-link-founder" id="next_thomas_hochstrasser" />
            <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
              <div className="img-area">
                <div className="img-container">
                  <img
                    src="/assets/images/founders/Thomas Hochstrasser.png"
                    alt="Thomas Hochstrasser"
                  />
                </div>

                <div className="about">
                  <div className="angle"></div>
                  <button onClick={() => setIsVisible3(!isVisible3)}>more</button>
                  <div className="right">
                    <div className="name">Thomas Hochstrasser</div>
                    <div className="designations">
                      <p>Attorney at law</p>
                    </div>
                    <div className="email-container">
                      <div className="email-address" >
                        <a
                          href="mailto:thomas.hochstrasser@ertnext.org"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="email next"
                        >
                          thomas.hochstrasser@ertnext.org
                        </a>
                      </div>
                    </div>
                    {/* <a
                      href="https://ch.linkedin.com/in/pascal-forster-23116424"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linkedin-icon-link"
                    >
                      <img
                        src="/assets/icons/linkedIn-icon.png"
                        alt="LinkedIn"
                        className="linkedin-icon"
                      />
                    </a> */}
                  </div>
                </div>
              </div>
            </ScrollAnimation>
            <div className={isVisible3 ? "data data-show" : "data"}>
              <button className="button-hide" onClick={() => setIsVisible3(!isVisible3)}>hide</button>
              <div className="testimonial">
                <ScrollAnimation animateIn="zoomIn" animateOnce delay={300}>
                  <p>
                    Thomas Hochstrasser studied law in Bern and Chicago
                    (Northwestern University) and obtained a business
                    certificate at IE University in Madrid. He has worked at
                    various courts and is today a partner and attorney-at-law at
                    Niederer Kraft Frey AG, a leading commercial law firm in
                    Zurich and Geneva. He advises private clients with a strong
                    focus on the next generation. Furthermore, he is a leading
                    expert in the field of sports law. In his practice areas, he
                    has been ranked as a 'recommended key lawyer' by the
                    market-leading lawyer ranking Legal 500 and won the Gold
                    Award in the category 'Lawyer of the Year IFC' at the
                    Citywealth Future Leaders Awards in London. He is a member
                    of the Board of Directors of several companies and Chairman
                    of a FINMA-regulated hedge fund. As a member of the
                    Foundation Board of the Laureus Foundation Switzerland he is
                    committed to helping socially disadvantaged children. In his
                    private life, he enjoys endurance sports in the nature like
                    Ironman, cycling and cross-country skiing.
                  </p>
                  <p>
                    Along with Thomas Ladner and Pascal Forster, he is a
                    co-founder of ERT NEXT.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
